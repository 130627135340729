import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Section, Title, Text, getProperty } from "@btc/shared";

import * as styles from "./teaser.module.scss";

export const Teaser = ({ data }) => {
  const title = getProperty(data, "title");
  const events = getProperty(data, "events") || [];
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section color="black" align="center">
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.container}>
            <Title wrapper="h1" size="s7" className={styles.title}>
              {title}
            </Title>
            <nav className={styles.nav}>
              {events.map((item) => {
                const path = getProperty(item, "frontmatter.path");
                const from = getProperty(item, "frontmatter.event.date.from");

                return (
                  <Link
                    key={path}
                    to={path}
                    className={styles.item}
                    activeClassName={styles.active}>
                    <Text size="s4" weight="bold">
                      {new Date(from).getFullYear()}
                    </Text>
                  </Link>
                );
              })}
            </nav>
          </div>
        </Container>

        <div className={styles.background}>
          <GatsbyImage image={image} alt={title} className={styles.image} />
        </div>
      </div>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment ArchiveEventTeaserFragment on MarkdownRemark {
    frontmatter {
      archive {
        title
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  fragment ArchiveEventTeaserEventsFragment on MarkdownRemark {
    frontmatter {
      path
      event {
        date {
          from
        }
      }
    }
  }
`;
