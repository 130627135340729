import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import { Container, Section, Text, Button, getProperty, ArrowIcon } from "@btc/shared";

import * as styles from "./gallery.module.scss";

export const Gallery = ({ data }) => {
  const categories = getProperty(data, "categories") || [];
  const gradient = getProperty(data, "theme.gradient");
  const linkText = getProperty(data, "link.text");
  const linkUrl = getProperty(data, "link.url");

  const [currentCategory, setCurrentCategory] = useState(() => getProperty(categories, "0"));
  const [currentImage, setCurrentImage] = useState(() => getProperty(currentCategory, "images.0"));

  const images = getProperty(currentCategory, "images") || [];

  const categoryHandler = (event, category) => {
    event.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    setCurrentImage(getProperty(category, "images.0"));
    setCurrentCategory(category);
  };

  const imageHandler = (event, image) => {
    setCurrentImage(image);
  };

  const prevHandler = (event) => {
    setCurrentImage((currentImage) => {
      const nextIndex = images.indexOf(currentImage) - 1;
      const nextImage =  images[nextIndex <= 0 ? images.length - 1 : nextIndex];
      return nextImage || currentImage;
    });
  };

  const nextHandler = (event) => {
    setCurrentImage((currentImage) => {
      const nextIndex = images.indexOf(currentImage) + 1;
      const nextImage =  images[nextIndex >= images.length ? 0 : nextIndex];
      return nextImage || currentImage;
    });
  };

  useEffect(() => {
    const thumb = document.querySelector(`[data-index="${images.indexOf(currentImage)}"]`);
    if (thumb) {
      thumb.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  }, [images, currentImage]);

  return categories.length > 0 ? (
    <Section color="black">
      <Container>
        {categories.length > 1 && (
          <nav className={styles.categories}>
            <div className={styles.scroller}>
            {categories.map((item, index) => {
              const category = getProperty(item, "category");
              const className = item === currentCategory
                ? [styles.category, styles.active].join(' ')
                : styles.category;
              const style =
                item === currentCategory
                  ? { borderImage: `${gradient} 1` }
                  : {};

              return (
                <button
                  key={index}
                  className={className}
                  onClick={(event) => categoryHandler(event, item)}>
                  <Text
                    size="s3"
                    style={style}
                    wrapper="span"
                    transform="uppercase"
                    className={styles.label} >
                    {category}
                  </Text>
                </button>
              )
            })}
            </div>
          </nav>
        )}
        <div className={styles.container}>
          <div className={styles.current}>
            <button onClick={prevHandler} className={styles.prev}>
              <ArrowIcon className={styles.icon}/>
            </button>
            <GatsbyImage image={getProperty(currentImage, "large.gatsbyImageData")} alt="" />
            <button onClick={nextHandler} className={styles.next}>
              <ArrowIcon className={styles.icon}/>
            </button>
          </div>
          <nav className={styles.thumbs}>
            {images.map((item, index) => {
              const image = getProperty(item, "small.gatsbyImageData");
              const style =
                getProperty(item, "id") === getProperty(currentImage, "id")
                  ? { borderImage: `${gradient} 1` }
                  : {};

              return (
                <button
                  key={index}
                  style={style}
                  data-index={index}
                  className={styles.item}
                  onClick={(event) => imageHandler(event, item)}>
                  <GatsbyImage image={image} alt="" className={styles.image} />
                </button>
              );
            })}
          </nav>
        </div>
        {linkUrl && (
          <div className={styles.link}>
            <Button wrapper="a" href={linkUrl} rel="noopener" target="_blank" color="white">{linkText}</Button>
          </div>
        )}
      </Container>
    </Section>
  ) : null;
};

Gallery.defaultProps = {
  data: {},
};

Gallery.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.shape({
          small: PropTypes.object.isRequired,
          large: PropTypes.object.isRequired,
        })).isRequired,
        category: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    theme: PropTypes.shape({
      gradient: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment ArchiveEventGalleryFragment on MarkdownRemark {
    frontmatter {
      event {
        gallery {
          categories {
              category
              images {
                  id
                  large: childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                  }
                  small: childImageSharp {
                      gatsbyImageData(width: 80, height: 80, layout: CONSTRAINED)
                  }
              }
          }
          link {
            text
            url
          }
        }
      }
      theme {
        gradient
      }
    }
  }
`;
