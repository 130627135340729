import React from "react";
import PropTypes from "prop-types";

import { Highlights as CommonHighlights } from "../common/highlights";

export const Highlights = ({ data }) => <CommonHighlights id="highlights" {...data} />;

Highlights.defaultProps = {
  data: {},
};

Highlights.propTypes = {
  data: PropTypes.shape({
    ...CommonHighlights.propTypes,
  }),
};
