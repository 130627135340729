import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container, Text, getProperty } from "@btc/shared";

import * as styles from "./speakers.module.scss";

export const Speakers = ({ data }) => {
  const items = getProperty(data, "items") || [];
  const gradient = getProperty(data, "theme.gradient");

  const sortedAndFilteredItems = useMemo(() => {
    items.sort((a, b) => {
      const nameA = getProperty(a, "name");
      const nameB = getProperty(b, "name");

      return nameA.localeCompare(nameB);
    });

    return items.filter((item) => {
      const talks = getProperty(item, "talks.length") > 0;
      const image = getProperty(item, "image");

      return talks && image;
    });
  }, [items]);

  return (
    <Section id="speakers" size="md" color="black" align="center" style={{ background: gradient }}>
      <Container>
        <div className={styles.container}>
          {sortedAndFilteredItems.map((item) => {
            const name = getProperty(item, "name");
            const role = getProperty(item, "role");
            const alias = getProperty(item, "alias");
            const image = getProperty(item, "image.childImageSharp.gatsbyImageData");

            return (
              <div key={alias} className={styles.item}>
                <GatsbyImage alt={name} image={image} className={styles.image} />
                <Text className={styles.text}>
                  <span>{name}</span>
                  {role && <span> - {role}</span>}
                </Text>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

Speakers.defaultProps = {
  data: {},
};

Speakers.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        role: PropTypes.string,
        image: PropTypes.object,
        alias: PropTypes.string.isRequired,
        talks: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      }).isRequired
    ).isRequired,
    theme: PropTypes.shape({
      gradient: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment ArchiveEventSpeakersFragment on MarkdownRemark {
    frontmatter {
      event {
        speakers {
          name
          role
          alias
          image {
            childImageSharp {
              gatsbyImageData(width: 500, height: 500, layout: CONSTRAINED)
            }
          }
          talks {
            alias
          }
        }
      }
      theme {
        gradient
      }
    }
  }
`;
