import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CarouselProvider, Slider, Slide, ButtonNext, ButtonBack } from "pure-react-carousel";

import {
  Section,
  Container,
  Text,
  Title,
  LG,
  XXL,
  useFormatterDate,
  useFormatterTime,
  useMatchMedia,
  getProperty,
  ArrowIcon,
  Markdown,
} from "@btc/shared";

import * as styles from "./highlights.module.scss";

const MEDIA_QUERIES = [`(min-width: ${LG}px)`, `(min-width: ${XXL}px)`];

const HighlightPropType = PropTypes.shape({
  from: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
  }).isRequired,
  schedule: PropTypes.shape({
    alias: PropTypes.string.isRequired,
    shortcut: PropTypes.string.isRequired,
  }).isRequired,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
});

export const Highlights = ({ title, content, items, theme, linkProvider, ...props }) => {
  const [isLG, isXXL] = useMatchMedia(MEDIA_QUERIES);
  const itemSize = isXXL ? 3 : isLG ? 2 : 1;

  return Array.isArray(items) && items.length > 0 ? (
    <Section {...props} size="md" color="black">
      <CarouselProvider
        step={itemSize}
        dragStep={itemSize}
        visibleSlides={itemSize}
        isIntrinsicHeight={true}
        lockOnWindowScroll={true}
        className={styles.wrapper}
        totalSlides={items.length}>
        <Container>
          <Title color="white">{title}</Title>
          {content && <Markdown content={content} />}
        </Container>

        <div className={styles.container}>
          <Slider className={styles.slider}>
            {items.map((item, index) => (
              <Slide key={index} index={index}>
                <Item item={item} theme={theme} linkProvider={linkProvider} />
              </Slide>
            ))}
          </Slider>
        </div>

        <Container>
          <div className={styles.buttons}>
            <ButtonBack className={styles.button}>
              <ArrowIcon className={styles.icon} />
            </ButtonBack>
            <ButtonNext className={styles.button}>
              <ArrowIcon className={styles.icon} />
            </ButtonNext>
          </div>
        </Container>
      </CarouselProvider>
    </Section>
  ) : null;
};

Highlights.defaultProps = {
  items: [],
  theme: null,
  title: null,
  content: null,
  linkProvider: null,
};

Highlights.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  items: PropTypes.arrayOf(HighlightPropType.isRequired).isRequired,
  theme: PropTypes.object,
  linkProvider: PropTypes.func,
};

const Item = ({ item, theme, linkProvider }) => {
  const from = getProperty(item, "from");
  const title = getProperty(item, "title");
  const content = getProperty(item, "content");
  const category = getProperty(item, "category");
  const speakers = getProperty(item, "speakers") || [];

  const date = useFormatterDate(from);
  const time = useFormatterTime(from);

  const labelCategory = getProperty(item, "category.name");
  const iconCategory = getProperty(item, "category.icon.svg.content");

  const labelSchedule = getProperty(item, "schedule.shortcut");

  const style =
    typeof getProperty(theme, "gradient") === "string"
      ? {
          borderImage: `${getProperty(theme, "gradient")} 1`,
        }
      : {};

  const to = typeof linkProvider === "function" ? linkProvider(item) : null;
  const wrapper = typeof to === "string" ? <Link to={to} /> : <div />;

  return (
    <wrapper.type {...wrapper.props} style={style} className={styles.item}>
      <div className={styles.header}>
        <Text size="s1" transform="uppercase">
          {date}
        </Text>

        <Text size="s1" transform="uppercase">
          {time}
        </Text>

        <Title wrapper="h3" transform="uppercase" className={styles.title}>
          {title}
        </Title>
      </div>
      <Text className={styles.content} truncate={500}>
        {content}
      </Text>
      <div className={styles.footer}>
        {category && (
          <div className={styles.category}>
            <div className={styles.icon} dangerouslySetInnerHTML={{ __html: iconCategory }} />
            <Text size="s1" transform="uppercase">
              {labelCategory}
            </Text>
          </div>
        )}

        {speakers.map((speaker, index) => (
          <div className={styles.image} key={index}>
            <GatsbyImage
              key={index}
              image={getProperty(speaker, "image.childImageSharp.gatsbyImageData")}
              alt=""
            />
          </div>
        ))}
      </div>
      <Text size="s3" weight="bold" className={styles.schedule}>
        {labelSchedule}
      </Text>
    </wrapper.type>
  );
};

Item.defaultProps = {
  item: null,
  theme: null,
  linkProvider: null,
};

Item.propTypes = {
  item: HighlightPropType.isRequired,
  theme: PropTypes.object,
  linkProvider: PropTypes.func,
};

export const query = graphql`
  fragment CommonEventHighlightsFragment on MarkdownRemark {
    frontmatter {
      event {
        contents {
          highlights {
            title
            content
          }
        }
        highlights {
          alias
          title
          from
          content
          category {
            name
            icon {
              svg {
                content
              }
            }
          }
          schedule {
            alias
            shortcut
          }
          speakers {
            alias
            name
            image {
              childImageSharp {
                gatsbyImageData(width: 100, height: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
