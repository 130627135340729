// extracted by mini-css-extract-plugin
export var active = "gallery-module--active--4bdrL";
export var categories = "gallery-module--categories--G5M46";
export var category = "gallery-module--category--iE2K0";
export var container = "gallery-module--container--10Hc3";
export var current = "gallery-module--current--EKzok";
export var icon = "gallery-module--icon--xzJup";
export var image = "gallery-module--image--PqAoS";
export var item = "gallery-module--item--fEkGs";
export var label = "gallery-module--label--OXTqJ";
export var link = "gallery-module--link--KGGce";
export var next = "gallery-module--next--vAdfW";
export var prev = "gallery-module--prev--U7uK6";
export var scroller = "gallery-module--scroller--MwiEL";
export var thumbs = "gallery-module--thumbs--lOe9G";