import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@btc/shared";

import { Teaser } from "./archive/teaser";
import { Nav } from "./archive/nav";
import { Gallery } from "./archive/gallery";
import { Highlights } from "./archive/highlights";
import { Speakers } from "./archive/speakers";
import { Meta } from "./common/meta";

const Template = ({ data }) => {
  const teaser = {
    ...getProperty(data, "teaser.frontmatter.archive"),
    events: (getProperty(data, "events.edges") || []).map((edge) => getProperty(edge, "node")),
  };
  const nav = getProperty(data, "nav.frontmatter.archive.nav");
  const highlights = {
    title: getProperty(data, "highlights.frontmatter.event.contents.highlights.title"),
    content: getProperty(data, "highlights.frontmatter.event.contents.highlights.content"),
    items: getProperty(data, "highlights.frontmatter.event.highlights"),
    theme: getProperty(data, "highlights.frontmatter.theme"),
  };
  const speakers = {
    items: getProperty(data, "speakers.frontmatter.event.speakers"),
    theme: getProperty(data, "speakers.frontmatter.theme"),
  };
  const gallery = {
    ...getProperty(data, "gallery.frontmatter.event.gallery"),
    theme: getProperty(data, "gallery.frontmatter.theme"),
  };
  const meta = getProperty(data, "page.frontmatter.meta");

  return (
    <Fragment>
      <Teaser data={teaser} />
      <Nav data={nav} />
      <Gallery data={gallery} />
      <Highlights data={highlights} />
      <Speakers data={speakers} />
      <Meta data={meta} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
        }
      }
    }

    teaser: markdownRemark(id: { eq: $id }) {
      ...ArchiveEventTeaserFragment
    }
    events: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "page" }, view: { eq: "archive" }, locale: { eq: $locale } }
      }
      sort: { fields: frontmatter___event___date___from, order: DESC }
    ) {
      edges {
        node {
          ...ArchiveEventTeaserEventsFragment
        }
      }
    }

    nav: markdownRemark(id: { eq: $id }) {
      ...ArchiveEventNavFragment
    }

    gallery: markdownRemark(id: { eq: $id }) {
      ...ArchiveEventGalleryFragment
    }

    highlights: markdownRemark(id: { eq: $id }) {
      ...CommonEventHighlightsFragment
      frontmatter {
        theme {
          gradient
        }
      }
    }

    speakers: markdownRemark(id: { eq: $id }) {
      ...ArchiveEventSpeakersFragment
    }
  }
`;
