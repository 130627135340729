// extracted by mini-css-extract-plugin
export var button = "highlights-module--button--hMrCc";
export var buttons = "highlights-module--buttons--R0oTt";
export var category = "highlights-module--category--4LiWp";
export var container = "highlights-module--container--VQiC4";
export var content = "highlights-module--content--+Dnn7";
export var footer = "highlights-module--footer--OeLru";
export var header = "highlights-module--header--QbSNj";
export var icon = "highlights-module--icon--q-Cby";
export var image = "highlights-module--image--n6WxK";
export var item = "highlights-module--item--Z41gF";
export var schedule = "highlights-module--schedule--KuIjO";
export var slider = "highlights-module--slider--Wd2Te";
export var title = "highlights-module--title--gzI8i";
export var wrapper = "highlights-module--wrapper--2P6vN";