import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { Container, Section, Text, getProperty } from "@btc/shared";

import * as styles from "./nav.module.scss";

export const Nav = ({ data }) => {
  const items = getProperty(data, "items") || [];

  return (
    <Section size="sm" color="black">
      <Container>
        <div className={styles.container}>
          <nav className={styles.nav}>
            {items.map((item) => {
              const href = getProperty(item, "url");
              const text = getProperty(item, "text");
              const props =
                typeof href === "string" && href.charAt(0) !== "#"
                  ? {
                      target: "_blank",
                      rel: "noopener",
                    }
                  : null;

              return (
                <a key={href} href={href} className={styles.item} {...props}>
                  <Text size="s3" transform="uppercase">
                    {text}
                  </Text>
                </a>
              );
            })}
          </nav>
        </div>
      </Container>
    </Section>
  );
};

Nav.defaultProps = {
  data: {},
};

Nav.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment ArchiveEventNavFragment on MarkdownRemark {
    frontmatter {
      archive {
        nav {
          items {
            text
            url
          }
        }
      }
    }
  }
`;
